import Layout, { WebsiteDataType } from "../components/Layout"
import React from "react"
export type pageContext = {
  pageContext: WebsiteDataType
}

const PageTemplate = ({ pageContext }: pageContext) => {
  return (
    <Layout
      thisPage={pageContext.thisPage}
      allPages={pageContext.allPages}
      cssVariables={pageContext.cssVariables}
      footerData={pageContext.footerData}
      headerData={pageContext.headerData}
      processedImageData={pageContext.processedImageData}
      videoData={pageContext.videoData}
      seoData={pageContext.seoData}
      siteOverviewData={pageContext.siteOverviewData}
      localeData={pageContext.localeData}
    />
  )
}

export default PageTemplate
